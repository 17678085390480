<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              ref="dxGridRef"
              title="Sponsee"
              canSelect="none"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadUsers()"
            >
              <template #action-buttons>
                <DxButton
                  icon="mention"
                  hint="Send email"
                  :onClick="onMailTo"
                />

                <DxButton
                  icon="email"
                  hint="Resend Verification"
                  :onClick="onResendVerification"
                />

                <DxButton
                  v-if="isAvailableToLink"
                  icon="link"
                  hint="Link account"
                  :onClick="onOpenModalLink"
                />
              </template>

              <template #action-toolbar>
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="primary"
                  @click="toggleIsAvailableToLink()"
                  title="View Available Link"
                >
                  <feather-icon
                    v-if="!isAvailableToLink"
                    class="cursor-pointer"
                    icon="LinkIcon"
                  />
                  <span v-else>Show All</span>
                </b-button>
                <b-button
                  size="sm"
                  class="mr-1"
                  variant="primary"
                  @click="onView"
                  title="View membership"
                >
                  <feather-icon icon="GitMergeIcon"></feather-icon>
                </b-button>
              </template>

              <template #columns>
                <DxColumn data-field="username" caption="Username" />
                <DxColumn data-field="email" caption="Email" />
                <DxColumn
                  data-field="membership_status"
                  caption="Membership Status"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="modalShow"
      centered
      @hide="onModalClose()"
      size="lg"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group label="First Name" label-for="v-name">
            <b-form-input v-model="formData.first_name"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Last Name" label-for="v-last_name">
            <b-form-input v-model="formData.last_name"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Email" label-for="v-email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                v-model="formData.email"
                :state="errors.length > 0 ? false : null"
                type="email"
                placeholder="Email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onModalClose()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          :disabled="isSaving"
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="modalLinkShow"
      centered
      @hide="onModalLinkClose()"
      size="lg"
      no-close-on-backdrop
    >
      <validation-observer ref="">
        <b-row>
          <b-col>
            <h1>
              {{ formDataLinkUser.display_name }}
            </h1>
          </b-col>
        </b-row>

        <label> Tree Type: L </label>
        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-l-user-name">
              <v-select
                :disabled="left_tree.isFilled"
                id="v-l-user-name"
                label="username"
                v-model="left_tree.username"
                :options="allUsers"
                :reduce="(users) => users.username"
                @option:selected="onSelectUser($event, 'L')"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Email" label-for="v-l-email">
              <b-form-input disabled id="v-l-email" v-model="left_tree.email" />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Acct ID" label-for="v-l-acct-id">
              <b-form-input
                :disabled="left_tree.isFilled"
                id="v-l-acct-id"
                v-model="left_tree.account_id"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Acct Name" label-for="v-l-acct-name">
              <b-form-input
                :disabled="left_tree.isFilled"
                id="v-l-acct-name"
                v-model="left_tree.account_name"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <label> Tree Type: R </label>
        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-r-user-name">
              <v-select
                :disabled="right_tree.isFilled"
                id="v-r-user-name"
                label="username"
                v-model="right_tree.username"
                :options="allUsers"
                :reduce="(users) => users.username"
                @option:selected="onSelectUser($event, 'R')"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Email" label-for="v-r-email">
              <b-form-input
                disabled
                id="v-r-email"
                v-model="right_tree.email"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Acct ID" label-for="v-r-acct-id">
              <b-form-input
                :disabled="right_tree.isFilled"
                id="v-r-acct-id"
                v-model="right_tree.account_id"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Acct Name" label-for="v-r-acct-name">
              <b-form-input
                :disabled="right_tree.isFilled"
                id="v-r-acct-name"
                v-model="right_tree.account_name"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <label> Tree Type: P1 </label>
        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-p1-user-name">
              <v-select
                :disabled="p1_tree.isFilled"
                id="v-p1-user-name"
                label="username"
                v-model="p1_tree.username"
                :options="allUsers"
                :reduce="(users) => users.username"
                @option:selected="onSelectUser($event, 'P1')"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Email" label-for="v-p1-email">
              <b-form-input disabled id="v-p1-email" v-model="p1_tree.email" />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Acct ID" label-for="v-p1-acct-id">
              <b-form-input
                :disabled="p1_tree.isFilled"
                id="v-p1-acct-id"
                v-model="p1_tree.account_id"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Acct Name" label-for="v-p1-acct-name">
              <b-form-input
                :disabled="p1_tree.isFilled"
                id="v-p1-acct-name"
                v-model="p1_tree.account_name"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <label> Tree Type: P2 </label>
        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-p2-user-name">
              <v-select
                :disabled="p2_tree.isFilled"
                id="v-p2-user-name"
                label="username"
                v-model="p2_tree.username"
                :options="allUsers"
                :reduce="(users) => users.username"
                @option:selected="onSelectUser($event, 'P2')"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Email" label-for="v-p2-email">
              <b-form-input disabled id="v-p2-email" v-model="p2_tree.email" />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Acct ID" label-for="v-p2-acct-id">
              <b-form-input
                :disabled="p2_tree.isFilled"
                id="v-p2-acct-id"
                v-model="p2_tree.account_id"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Acct Name" label-for="v-p2-acct-name">
              <b-form-input
                :disabled="p2_tree.isFilled"
                id="v-p2-acct-name"
                v-model="p2_tree.account_name"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <label> Tree Type: P3 </label>
        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-p3-user-name">
              <v-select
                :disabled="p3_tree.isFilled"
                id="v-p3-user-name"
                label="username"
                v-model="p3_tree.username"
                :options="allUsers"
                :reduce="(users) => users.username"
                @option:selected="onSelectUser($event, 'P3')"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Email" label-for="v-p3-email">
              <b-form-input disabled id="v-p3-email" v-model="p3_tree.email" />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Acct ID" label-for="v-p3-acct-id">
              <b-form-input
                :disabled="p3_tree.isFilled"
                id="v-p3-acct-id"
                v-model="p3_tree.account_id"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Acct Name" label-for="v-p3-acct-name">
              <b-form-input
                :disabled="p3_tree.isFilled"
                id="v-p3-acct-name"
                v-model="p3_tree.account_name"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="onModalLinkClose()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          v-b-modal.modal-confirmation
          size="sm"
          variant="success"
          class="bg-darken-4"
        >
          Link
        </b-button>
      </template>

      <Modal modalId="modal-confirmation" @onChange="(v) => onSubmitLink()" />
    </b-modal>
  </section>
</template>

<script>
import userApi from '@api/users';
import userMembershipApi from '@api/user_memberships';
import { queryParameters } from '@/schema';

const formCommonFields = {
  username: '',
  email: '',
  parent_id: 0,
  account_id: '',
  account_name: '',
  membership_status: 'new',
  quantity_active_indirect: 0,
  quantity_active_direct: 0,
  isFilled: false,
};

export default {
  name: 'UserMembershipPage',
  components: {},
  data: () => ({
    _dataSource: [],

    modalShow: false,
    modalLinkShow: false,
    isAvailableToLink: false,
    isSaving: false,
    dataSource: [],
    allUsers: [],
    members: [],

    formData: {
      id: 0,
      first_name: '',
      last_name: '',
      roles: ['user'],
      status: 'O',
      uuid: '',
      parent_id: 0,
      tree_id: 0,
      linked_status: 'NotLinked',
      user_type: 'm',
    },

    formDataLinkUser: {
      username: '',
      L: {},
      R: {},
      P1: {},
      P2: {},
      P3: {},
    },

    left_tree: {
      ...formCommonFields,
      tree_type: 'L',
    },
    right_tree: {
      ...formCommonFields,
      tree_type: 'R',
    },
    p1_tree: {
      ...formCommonFields,
      tree_type: 'P1',
    },
    p2_tree: {
      ...formCommonFields,
      tree_type: 'P2',
    },
    p3_tree: {
      ...formCommonFields,
      tree_type: 'P3',
    },

    userData: JSON.parse(window.localStorage.getItem('userData')) || {},
  }),
  computed: {
    //
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `user_type='m',active=true`,
      };

      if (this.userData && this.userData.role !== 'admin') {
        params.filterExt += `,tree_id=${this.userData.id}|sponsor_id=${this.userData.id}`;
      }

      userApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            this.allUsers.push(...data);
            this.dataSource = data;
            this._dataSource = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadUserMembers(item) {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `parent_id=${item.id}`,
      };

      userMembershipApi
        .list(params)
        .then(({ data }) => {
          data.forEach((e) => {
            const status = e.account_status;

            if (e.tree_type == 'L') {
              if (status === 'Filled') {
                this.left_tree = e;
                this.left_tree.isFilled = true;

                return;
              }

              this.left_tree.isFilled = false;
            }

            if (e.tree_type == 'R') {
              if (status === 'Filled') {
                this.right_tree = e;
                this.right_tree.isFilled = true;

                return;
              }

              this.right_tree.isFilled = false;
            }

            if (e.tree_type == 'P1') {
              if (status === 'Filled') {
                this.p1_tree = e;
                this.p1_tree.isFilled = true;

                return;
              }

              this.p1_tree.isFilled = false;
            }

            if (e.tree_type == 'P2') {
              if (status === 'Filled') {
                this.p2_tree = e;
                this.p2_tree.isFilled = true;

                return;
              }

              this.p2_tree.isFilled = false;
            }

            if (e.tree_type == 'P3') {
              if (status === 'Filled') {
                this.p3_tree = e;
                this.p3_tree.isFilled = true;

                return;
              }

              this.p3_tree.isFilled = false;
            }
          });
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const successFn = () => {
        this.isSaving = true;
        this.loadUsers();
        this.onModalClose();
        this.toastConfig();
      };

      if (this.formData.id) {
        userApi
          .update(this.formData)
          .then(({ data }) => {
            if (data) {
              successFn();
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });

        return;
      }

      userApi
        .add(this.formData)
        .then(({ data }) => {
          successFn();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmitLink() {
      const _formdata = {
        parent_id: +this.formDataLinkUser.id,
        L: this.left_tree,
        R: this.right_tree,
        P1: this.p1_tree,
        P2: this.p2_tree,
        P3: this.p3_tree,
      };

      userMembershipApi
        .updateTreeMultiple(_formdata)
        .then(({ data }) => {
          if (data) {
            this.onModalLinkClose();
            this.loadUsers();

            this.toastConfig();
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onOpenModal(event) {
      this.modalShow = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onOpenModalLink(event) {
      this.modalLinkShow = true;

      if (event?.row?.data) {
        this.formDataLinkUser = event.row.data;
        this.loadUserMembers(event.row.data);
      }
    },
    onEdit(data) {
      this.$nextTick(() => {
        this.formData = data;
      });
    },
    onView() {
      if (this.userData) {
        const url = `/pages/admin/sponsee/view?id=${this.userData.uuid}`;
        this.$router.push(url);
      }
    },
    onDelete(event) {
      userApi
        .delete(event)
        .then(() => {
          this.loadUsers();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onMailTo(event) {
      const {
        row: { data },
      } = event;

      if (data) {
        document.location.href = `mailto:${data.email}`;
      }
    },
    onModalClose() {
      this.modalShow = false;

      const defaultForm = {
        id: 0,
        first_name: '',
        last_name: '',
        roles: ['user'],
        status: 'O',
        uuid: '',
        parent_id: 0,
        tree_id: 0,
        linked_status: 'NotLinked',
        user_type: 'm',
      };

      this.$nextTick(() => {
        this.formData = defaultForm;
      });
    },
    onModalLinkClose() {
      this.modalLinkShow = false;

      const defaultForm = {
        username: '',
        L: {},
        R: {},
        P1: {},
        P2: {},
        P3: {},
      };

      const defaultFormL = {
        ...formCommonFields,
        tree_type: 'L',
      };
      const defaultFormR = {
        ...formCommonFields,
        tree_type: 'R',
      };
      const defaultFormP1 = {
        ...formCommonFields,
        tree_type: 'P1',
      };
      const defaultFormP2 = {
        ...formCommonFields,
        tree_type: 'P2',
      };
      const defaultFormP3 = {
        ...formCommonFields,
        tree_type: 'P3',
      };

      this.$nextTick(() => {
        this.formDataLinkUser = defaultForm;
        this.left_tree = defaultFormL;
        this.right_tree = defaultFormR;
        this.p1_tree = defaultFormP1;
        this.p2_tree = defaultFormP2;
        this.p3_tree = defaultFormP3;
      });
    },
    onGetLinkedUser(users) {
      const items = [];
      const isLinkedUser = users.filter((x) => {
        return x.linked_status === 'Linked';
      });

      if (this.userData) {
        items.push(this.userData);
      }

      items.push(...isLinkedUser);

      return items;
    },
    onResendVerification(event) {
      const {
        row: { data },
      } = event;

      if (data.membership_status == 'new') {
        userApi
          .resendVerification(data)
          .then(({ data }) => {
            if (data) {
              this.toastConfig();
            }
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });

        return;
      }

      this.toastConfig('', 'error')
    },
    onSelectUser(event, type) {
      switch (type) {
        case 'L':
          this.left_tree.email = event.email;
          break;

        case 'R':
          this.right_tree.email = event.email;
          break;

        case 'P1':
          this.p1_tree.email = event.email;
          break;

        case 'P2':
          this.p2_tree.email = event.email;
          break;

        case 'P3':
          this.p3_tree.email = event.email;
          break;

        default:
          break;
      }
    },
    toggleIsAvailableToLink() {
      this.isAvailableToLink = !this.isAvailableToLink;

      const linkedUsers = this.dataSource.filter((x) => {
        return x.linked_status === 'Linked' && x.active === true;
      });

      if (this.isAvailableToLink) {
        this.dataSource = linkedUsers;
        return;
      }

      this.dataSource = this._dataSource;
    },
  },
};
</script>

<style></style>
